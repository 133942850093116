import { LoadedTestExecution } from './proband/LoadedTestExecution';
import React from 'react';
import { finalScreeNodeTag } from '../graph/nodes/tree-node-tag';
import { Box, Button, Typography } from '@mui/material';
import { ControlOccurrenceEvent } from '../controls/control-monitor';
import { InterceptingControlStereotype } from '../controls/intercepting-control-stereotype';
import { TimeMeasuredValue } from '../../../events/TimedEvent';
import { translations } from '../state/baseTestContext/app-configuration/translations/translation-builder';
import { useRunnerAppTranslator } from '../state/baseTestContext/app-configuration/translations/translator.hook';
import { Translations } from '../state/baseTestContext/app-configuration/translations/app-translations';

export function TestCancelButton({
  loadedTest,
}: {
  loadedTest: LoadedTestExecution;
}) {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Control.CancelTestButtonTranslations,
  );
  const endNode = React.useMemo(
    () =>
      Array.from(loadedTest.treeSequence.nodeMap.values()).find((t) =>
        t.node.hasTag(finalScreeNodeTag),
      ),
    [loadedTest.treeSequence.nodeMap],
  );
  const [display, setDisplay] = React.useState(!!endNode);
  React.useEffect(() => {
    const sub = loadedTest.subject.subscribe((val) => {
      setDisplay(!!endNode && val.screenNode !== endNode);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return display ? (
    <Box
      sx={{
        position: 'absolute',
        bottom: '0.25em',
        right: '0.5em',
        cursor: 'pointer',
        color: 'grey',
      }}
    >
      <Button
        sx={{ color: '#666666', textTransform: 'unset' }}
        onClick={(e) => {
          ControlOccurrenceEvent.dispatchControlOccurrence(
            InterceptingControlStereotype.EXIT_EXECUTION_CONTROL.controlOccurred(
              TimeMeasuredValue.now(
                Object.assign(e, {
                  finalizationId: loadedTest.resultManager.testFinalizationId,
                }),
              ),
            ),
          );
        }}
      >
        {screenTranslator.message.translate().join(' / ')}
      </Button>
    </Box>
  ) : (
    <></>
  );
}

export const CancelTestButtonTranslations = translations.scope(
  'Cancel Test by User Request',
  {
    message: translations.text('Cancel Test message', {
      de: 'Test abbrechen',
      en: 'Cancel test.',
    }),
  },
);
