import { TestType } from '../../../../types';
import { TreeSequence } from '../../../graph/tree-sequence/tree-sequence';
import { SortedTree } from '../../../graph/sorted-tree/sorted-tree';
import { MapScreenTreeNode } from '../../../graph/nodes/map-screen-tree-node';
import { StimulusContent } from '../../../screens/basic-views/stimulus-content';
import { TestTreeWalker, TrialPreviewTree } from './tree-walkers';
import { Iat } from '../../../../../tests/IAT/types';
import { IatOptionsData } from '../../../../../tests/IAT/loader/view-data/iat-options-data';

export const iatTreeWalker: TestTreeWalker<Iat> = {
  testType: TestType.IAT,
  transformTreeSequence: (
    treeSequence: TreeSequence,
    sortedTree: SortedTree,
    testAccessor,
  ): TrialPreviewTree & {
    structureInfo: {
      attributeCategories: { label: string; stimuliSize: number }[];
      targetCategories: { label: string; stimuliSize: number }[];
    };
  } => {
    console.log('IAT tree walker', sortedTree);
    const blocksNodes = sortedTree.rootNode.children.find(
      (n) => n.name === 'Blocks',
    )?.children;
    if (!blocksNodes) {
      throw new Error('No blocks found in tree');
    }

    const structureInfo = {
      attributeCategories: [
        testAccessor.modelData.attributeCategories.categoryA,
        testAccessor.modelData.attributeCategories.categoryB,
      ].map((c) => ({
        label: c.label.text!,
        stimuliSize: c.testStimuliPool.length,
      })),
      targetCategories: [
        testAccessor.modelData.targetCategories.categoryA,
        testAccessor.modelData.targetCategories.categoryB,
      ].map((c) => ({
        label: c.label.text!,
        stimuliSize: c.testStimuliPool.length,
      })),
    };

    const blocks = blocksNodes.map((blockNode, blockIndex) => {
      const blockInfo = testAccessor.modelData.blocks[blockIndex];

      const trialsCandidates = blockNode.children.find(
        (n) => n.name === 'Trials',
      );
      console.log('blockNode', blockNode, blockNode.children);
      if (!trialsCandidates) {
        throw new Error('No trials found in block');
      }
      const stimulusMap = new Map(
        [
          testAccessor.modelData.attributeCategories.categoryA.testStimuliPool.map(
            (s, listIndex) => ({
              type: 'attribute' as const,
              categoryLabel:
                testAccessor.modelData.attributeCategories.categoryA.label.text,
              listIndex,
              stimulusType: s.text ? ('text' as const) : ('image' as const),
              stimulusId: s.text ?? s.id,
              stimulusData: s,
            }),
          ),
          testAccessor.modelData.attributeCategories.categoryB.testStimuliPool.map(
            (s, listIndex) => ({
              type: 'attribute' as const,
              categoryLabel:
                testAccessor.modelData.attributeCategories.categoryB.label.text,
              listIndex,
              stimulusType: s.text ? ('text' as const) : ('image' as const),
              stimulusId: s.text ?? s.id,
              stimulusData: s,
            }),
          ),
          testAccessor.modelData.targetCategories.categoryA.testStimuliPool.map(
            (s, listIndex) => ({
              type: 'target' as const,
              categoryLabel:
                testAccessor.modelData.targetCategories.categoryA.label.text,
              listIndex,
              stimulusType: s.text ? ('text' as const) : ('image' as const),
              stimulusId: s.text ?? s.id,
              stimulusData: s,
            }),
          ),
          testAccessor.modelData.targetCategories.categoryB.testStimuliPool.map(
            (s, listIndex) => ({
              type: 'target' as const,
              categoryLabel:
                testAccessor.modelData.targetCategories.categoryB.label.text,
              listIndex,
              stimulusType: s.text ? ('text' as const) : ('image' as const),
              stimulusId: s.text ?? s.id,
              stimulusData: s,
            }),
          ),
        ]
          .flat()
          .map((s) => [s.stimulusId, s]),
      );
      const trials = trialsCandidates.children.map((trialNode, index) => {
        const trialStimulusNode = trialNode.children[0];
        if (
          !trialStimulusNode ||
          !(trialStimulusNode.treeNode instanceof MapScreenTreeNode) ||
          !(trialStimulusNode.treeNode.screenData instanceof IatOptionsData) ||
          !(
            trialStimulusNode.treeNode.screenData.content instanceof
            StimulusContent
          )
        ) {
          throw new Error('No stimulus found in trial');
        }
        const stimulus = trialStimulusNode.treeNode.screenData.content.stimulus;
        const stimulusScreenData = trialStimulusNode.treeNode.screenData;
        const stimulusType = stimulus.data.textValue ? 'text' : 'image';
        const stimulusMediaItemSnapshot = stimulus.data.toMediaItemSnapshot();
        const stimulusNode = stimulusMap.get(stimulus.data.identifier);
        if (!stimulusNode) {
          console.log(stimulus, trialStimulusNode.treeNode);
          throw new Error('Stimulus not found');
        }
        // console.log('Target stimulus', targetNode.treeNode, targetNodeStimulus);
        return {
          trialName: trialNode.name,
          trialIndex: index,
          presentedStimuli: [
            {
              stimulusType: stimulusNode.type,
              stimulusInfo: {
                categoryTypeName: stimulusNode.type,
                categoryLabel: stimulusNode.categoryLabel,
                contentType: stimulusNode.stimulusType,
                id: stimulusNode.stimulusId,
                textValue: stimulus.data.textValue,
                listIndex: stimulusNode.listIndex,
              },
              stimulusData: stimulus,
            },
          ],
        };
      });
      return {
        name: `${blockInfo.name}(${blockNode.name})`,
        blockInfo: {
          blockType: blockInfo.type,
          amountOfTrials: blockInfo.amountTrials,
          blockOptions: [
            blockInfo.attributeCategoryVariant ? 'attribute' : undefined,
            blockInfo.targetCategoryVariant ? 'target' : undefined,
          ].filter((v): v is 'attribute' | 'target' => !!v),
        },
        trials,
      };
    });

    return {
      structureInfo,
      blocks,
    };
  },
};
